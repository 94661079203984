@font-face {
  font-family: 'Optima';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/Optima-Regular.ttf');
}

@font-face {
  font-family: 'Optima';
  font-weight: 400;
  src: url('../fonts/Optima-Italic.ttf');
}

@font-face {
  font-family: 'Optima';
  font-weight: 700;
  src: url('../fonts/Optima-Bold.ttf');
}

@font-face {
  font-family: 'Optima';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/Optima-Bold-Italic.ttf');
}

@font-face {
  font-family: 'Optima';
  font-weight: 900;
  src: url('../fonts/Optima-ExtraBlack.ttf');
}

.font-sans {
  font-family: 'Asap', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
}

.font-nunito {
  font-family: 'Nunito', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
}

.font-optima {
  font-family: 'Optima', ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif;
}
