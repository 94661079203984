@import './fonts.scss';
@import './utils.scss';
@import './keyframes.scss';

li {
  list-style: none;
}

button {
  border: none;
  font-family: inherit;
}
