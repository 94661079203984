@import '../../styles/lib';

.root {
  --size: 2rem;
  --border: calc(var(--size) * 0.1);
  display: block;
  @include box(var(--size), var(--size), 50%);
  @include border(var(--border) solid rgb(241, 239, 239), $t: var(--border) solid var(--spinner-color));
  animation: spin 0.75s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
