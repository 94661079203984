@import '../styles/lib';

.tcm {
  --img-rad: 0;
  --img-pad: 0;
  --play-icon: #8a1128;
  --header-bg: #fffbf5;
  --brand-color: #8a1128;

  .header {
    h1 {
      @include font(#8a6d3b, $weight: 400);
    }
    h2 {
      @extend .font-optima;
    }
  }
}

.owm {
  --img-rad: 0.8rem;
  --img-pad: 2rem;
  --play-icon: #3c6e8a;
  --header-bg: #f0f9ff;
  --brand-color: #29a0e4;
  .header {
    h1 {
      @include font(#911a1d, $weight: 700);
    }
    h2 {
      @extend .font-nunito;
    }
  }
}

.main {
  --spinner-color: var(--brand-color);
  @extend .font-sans;
  @include font(#333333);
}

.header {
  @include box($bg: var(--header-bg));
  @include gap($y: 1rem);
  @include padding(2rem);
  h1 {
    @include font($size: 1.4rem);
  }
  h2 {
    @include font($size: 2.4rem, $weight: 700, $height: 3rem);
  }
  h3 {
    @include font($size: 1.4rem, $weight: 400);
  }
}

.content {
  .text {
    @include padding(2rem);
    * {
      @include font($height: 2.1rem);
    }
    ul {
      @include padding($l: 1rem);
    }
  }
}

.action {
  @include box($bg: #fff);
  @include flex(center, center);
  @include cords(fixed, $b: 0, $x: 0);
  @include border($t: 1px solid rgba(#a8a8a8, 0.2));
  @include padding($t: 2.5rem, $b: 4rem, $x: 2rem);
  a {
    @include box(100%, $r: 0.6rem, $bg: var(--brand-color));
    @include font(#fff, 1.6rem, 600, $align: center);
    @include padding($y: 1.8rem);
    text-decoration: none;
  }
}

.loading {
  @include box(100vw, 100vh);
  @include flex(center, center);
}
