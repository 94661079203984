@keyframes fade-in {
  0% {
    opacity: var(--o);
    transform: translate(var(--x), var(--y));
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes fade-out {
  0% {
    opacity: var(--o);
    transform: translate(var(--x), var(--y));
  }
  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}

@keyframes grow-up {
  0% {
    opacity: var(--o);
    max-width: 0px;
    max-height: 0px;
  }
  100% {
    opacity: 1;
    max-width: var(--w);
    max-height: var(--h);
  }
}

@keyframes grow-down {
  0% {
    opacity: 1;
    max-width: var(--w);
    max-height: var(--h);
  }
  100% {
    opacity: var(--o);
    max-width: 0px;
    max-height: 0px;
  }
}

@keyframes scale-in {
  0% {
    opacity: var(--o);
    transform: scale(var(--s));
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scale-down {
  0% {
    opacity: var(--o);
    transform: scale(var(--s));
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes wave {
  50% {
    transform: rotate(25deg);
  }
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
