@import '../../styles/lib';

.root {
  :global .splide__ {
    &sr {
      display: none; // A useless ... cause layout shift
    }
    &list {
      @include flex();
    }
    &track {
      @include box(calc(100vw - var(--img-pad) * 2), $r: var(--img-rad));
      @include margin($x: var(--img-pad));
      @include overflow(hidden);
    }
    &slide {
      flex-shrink: 0;
      @include box(100%, 100vw);
      @include cords(relative);
      @include overflow(hidden);
      img {
        object-fit: cover;
        object-position: center;
        @include box(100%, 100%);
        @include cords(absolute, $x: 0, $y: 0);
      }
    }
    &pagination {
      @include gap($x: 0.5rem);
      @include flex(center);
      @include margin($t: 2rem);
    }
    &pagination__page {
      @include box(0.85rem, 0.85rem, 0.5rem, #d9d9d9);
      &.is-active {
        @include box($bg: var(--brand-color));
      }
    }
  }
}

.full {
  :global .splide__ {
    &slide {
      @include box($h: 100vh);
    }
  }
}
